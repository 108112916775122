
import { defineComponent } from "vue";
import LightIcon from "./LightIcon.vue";
import DarkIcon from "./DarkIcon.vue";
export default defineComponent({
  name: "CustomIcon",
  components: { LightIcon, DarkIcon },
  props: {
    indicator: {
      type: Object,
      default: () => ({}),
    },
    isEdited: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
  },
});

const indicatorsList = [
  {
    name: "doc",
    tooltip: "document",
    contentTypeIds: [
      3, 26, 82, 121, 167, 187, 208, 210, 211, 213, 215,
    ],
  },
  {
    name: "video",
    tooltip: "video",
    contentTypeIds: [
      138, 144, 169, 182, 183, 184, 185, 186, 188, 192, 193, 194, 198, 202, 207, 212, 214,
    ],
  },
  {
    name: "customdoc",
    tooltip: "custom document",
    contentTypeIds: [
      22,
    ],
  },
  {
    name: "custompdf",
    tooltip: "custom pdf",
    contentTypeIds: [
      205,
    ],
  },
];

function getIndicator (id: number) {
  const indicator = indicatorsList.find(i => i.contentTypeIds.indexOf(id) !== -1) || null;
  return {
    name: indicator?.name || "doc",
    tooltip: indicator?.tooltip || "document",
  };
}

export default getIndicator;

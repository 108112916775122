
import { defineComponent, ref, PropType, computed } from "vue";

import CustomIcon from "./CustomIcon.vue";
import UserModule from "../store/modules/User/user";
import UiConfiguration from "@/store/modules/UIConfiguration/uiConfiguration";

import getIndicator from "../utility/getIndicator";
import formatText from "../utility/formatText";
import { ContentList } from "@/models/content/content";

export default defineComponent({
  components: {
    CustomIcon,
  },
  props: {
    selectedMaterials: {
      type: [] as PropType<ContentList>,
      default: () => ([] as ContentList[]),
    },
    fileSelector: {
      type: Boolean,
      default: false,
    },
    showClearAll: {
      type: Boolean,
      default: false,
    },
    showPrintOptions: {
      type: Boolean,
      default: false,
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
    isSharing: {
      type: Boolean,
      default: false,
    },
  },
  setup () {
    const isMultiselectMenuOpened = ref(false);
    const userDisplaySettings = computed(() => UserModule.displaySettings);
    const uiConfiguration = computed(() => UiConfiguration.uiConfiguration);
    const showPrintButton = computed((): boolean => {
      return UserModule.displaySettings.printButton?.visibility === 1;
    });

    return {
      isMultiselectMenuOpened,
      getIndicator,
      formatText,
      userDisplaySettings,
      uiConfiguration,
      showPrintButton,
    };
  },
});

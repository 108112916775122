
import { defineComponent, ref, onMounted } from "vue";
import DarkEditedDoc from "./icons/DarkEditedDoc.vue";
import DarkEditedVideo from "./icons/DarkEditedVideo.vue";
import DarkEditedCustomDoc from "./icons/DarkEditedCustomDoc.vue";
export default defineComponent({
  name: "DarkIcon",
  components: { DarkEditedDoc, DarkEditedVideo, DarkEditedCustomDoc },
  props: {
    indicator: {
      type: Object,
      default: () => ({}),
    },
    isEdited: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
  },
  setup (props) {
    const iconToRender = ref();
    onMounted(() => {
      switch (props.indicator.name) {
        case "doc":
          iconToRender.value = DarkEditedDoc;
          break;
        case "video":
          iconToRender.value = DarkEditedVideo;
          break;
        case "customdoc": {
          iconToRender.value = DarkEditedCustomDoc;
          break;
        }
      }
    });
    return { iconToRender };
  },
});


import { defineComponent } from "vue";
export default defineComponent({
  name: "LightIcon",
  props: {
    indicator: {
      type: Object,
      default: () => ({}),
    },
    isEdited: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "",
    },
  },
});
